<template>
  <router-view></router-view>
  <!-- <p>IP Visitor: {{ visitor.ip }}</p>
  <p>Region: {{ visitor.region }}</p>
  <p>Referer: {{ visitor.referer }}</p>
  <p>Device: {{ visitor.device }}</p> -->
</template>

<script>
// import axios from 'axios';
export default {
  data() {
    return {
      // ip: null,
      // region: null,
      // referer: null,
      // device: null,
    };
  },
  // mounted() {
  //   this.fetchVisitorInfo();
  // },
  // methods: {
  //   async fetchVisitorInfo() {
  //     try {
  //       const response = await axios.get('https://shortener-backend.test/api/links/getIp'); // Ganti dengan URL endpoint yang sesuai
  //       this.visitor = response.data;
  //     } catch (error) {
  //       console.error('Gagal mendapatkan alamat IP publik', error);
  //     }
  //   },
  // },
};
</script>
