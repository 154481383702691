export default [
  {
    path: "/",
    redirect: "/welcome",
  },
  {
    path: "/welcome",
    name: "default",
    meta: {
      title: "SepUrl",
      authRequired: false,
    },
    component: () => import("../views/home/index"),
  },
  {
    path: "/privacy-policy",
    name: "privacy",
    meta: {
      title: "SepUrl Privacy policy",
      authRequired: false,
    },
    component: () => import("../views/home/privacy"),
  },
  {
    path: "/terms-of-conditions",
    name: "tos",
    meta: {
      title: "SepUrl Terms of service",
      authRequired: false,
    },
    component: () => import("../views/home/tos"),
  }
];
